import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '../../../common/Container';
import { Hero, WhyUs, HowItWorks } from './components';
import LargeYoga from '../../../images/largeyoga.jpg';

const Home = () => {
  const theme = useTheme();

  return (
    
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          zIndex: 0,
          backgroundImage: `url(${LargeYoga})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          opacity: 1,
        }}
      />
      
      <Box
        sx={{
          position: 'relative',
          zIndex: 2,
        }}
      >
        <Container>
          <Hero />
        </Container>
      </Box>

      <Box
      bgcolor={theme.palette.alternate.main}
        sx={{
          position: 'relative',
          zIndex: 2,
        }}
      >
      <Container>
        <HowItWorks />
      </Container>
      </Box>
      <Box 
      bgcolor={'transparent'}
      sx={{
        position: 'relative',
        zIndex: 2,
      }}
      >
        <Container>
          <WhyUs />
        </Container>
        </Box>
    </Box>
  
  );
};

export default Home;
