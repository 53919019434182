import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import NamTreeLogo from '../../../../images/namtree-removebg.png'


const Topbar = ({ onSidebarOpen }) => {


  const [activeLink, setActiveLink] = useState('');
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : '');
  }, []);
  return (
    <>
    <Box
      display={'flex'}
      justifyContent={{ xs: 'none', sm: 'space-between' }}
      alignItems={'center'}
      width={'100%'}
    >
       <Box marginRight={{ xs: 1, sm: 2 }} sx={{ display: { xs: 'flex', md: 'none' } }}>
          <IconButton onClick={onSidebarOpen} aria-label="Menu">
            <MenuIcon sx={{ color: 'white' }}/>
          </IconButton>
        </Box>
      <Box display={{ xs: 'flex', md: 'none' }} height={'30px'}  flexGrow={2}>
    <Box display={'flex'} height={'100%'} width={'100%'}>
    </Box>

      </Box>
     
      </Box>

<Box
sx={{ display: { xs: 'none', md: 'flex'}, marginTop: { xs: 0, md: 1}}}
justifyContent={'center'}
alignItems={'center'}
width={'100%'}
>
  <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'} marginTop={1}>
  <Box marginX={2}>
      <Typography 
      component="a" 
      href="/" 
      sx={{textDecoration: 'none'}} 
      fontWeight="bold" 
      color={activeLink === '/area' ? 'primary' : 'white'}>
        Inicio
      </Typography>
    </Box>
    <Box marginX={2}>
      <Typography 
      component="a" 
      href="/yoga" 
      sx={{textDecoration: 'none'}} 
      fontWeight="bold" 
      color={activeLink === '/area' ? 'primary' : 'white'}>
        Yoga
      </Typography>
    </Box>
    <Box marginX={2}>
      <Typography 
      component="a" 
      href="/numerología " 
      sx={{textDecoration: 'none'}} 
      fontWeight="bold" 
      color={activeLink === '/lawncare' ? 'primary' : 'white'}>
        Numerología 
      </Typography>
    </Box>
    <Box marginX={2}>
      <Typography 
      component="a" 
      href="/ceremonias-holísticas " 
      sx={{textDecoration: 'none'}} 
      fontWeight="bold" 
      color={activeLink === '/supplies' ? 'primary' : 'white'}>
        Ceremonias Holísticas 
      </Typography>
    </Box>
    <Box marginX={2}>
      <Typography 
      component="a" 
      href="/contacto" 
      sx={{textDecoration: 'none'}} 
      fontWeight="bold" 
      color={activeLink === '/tools' ? 'primary' : 'white'}>
        Contacto
      </Typography>
    </Box>
    <Box marginX={2}>
      <Typography 
      component="a" 
      href="/sobre" 
      sx={{textDecoration: 'none'}} 
      fontWeight="bold" 
      color={activeLink === '/mission' ? 'primary' : 'white'}>
        Sobre
      </Typography>
    </Box>
  </Box>
</Box>
   </> 
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  themeToggler: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
  setThemePalette: PropTypes.func.isRequired,
  paletteType: PropTypes.string.isRequired,
};

export default Topbar;
