import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactComponent as SimplscapeLogo } from '../../../../svg/logos/SimplscapeLogo.svg';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import IconButton from '@mui/material/IconButton';

const Footer = () => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        width={'100%'}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box
          display={'flex'}
          component="a"
          underline="none"
          href="/"
          title="namtree"
          height={24}
          width={35}
        >
         
        </Box>
        <Box display="flex" flexWrap={'wrap'} alignItems={'center'}>
        <IconButton aria-label="facebook" target={'_blank'} rel={'noopener noreferrer'} href={'https://www.facebook.com/profile.php?id=100077049332173'}>
                  <FacebookIcon />
                </IconButton>
                <IconButton aria-label="instagram" target={'_blank'} rel={'noopener noreferrer'} href={'https://www.instagram.com/nam.tree/'}>
                  <InstagramIcon />
                  </IconButton>
        </Box>
      </Box>
    </Grid>
    <Grid item xs={12}>
      <Typography
        align={'center'}
        variant={'subtitle2'}
        color="textSecondary"
        gutterBottom
      >
        &copy; 2022 NamTree | All rights reserved
      </Typography>
      <Typography
        align={'center'}
        variant={'caption'}
        color="textSecondary"
        component={'p'}
      >
        Cuando visitas o interactúas con nuestros sitios, servicios o herramientas, 
        nosotros o nuestros proveedores de servicios autorizados podemos utilizar 
        cookies para almacenar información que ayude a proporcionarte una experiencia 
        mejor, más rápida y más segura, así como para fines de marketing.
      </Typography>
    </Grid>
  </Grid>
);

export default Footer;
