import React from 'react'
import { Box } from '@mui/material';
import LargeYoga from '../../../images/largeyoga.jpg';

function Yoga() {
  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 0,
          backgroundImage: `url(${LargeYoga})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          opacity: 1,
        }}
      />
      
    </Box>
  )
}

export default Yoga