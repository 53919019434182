import React from 'react';
import { Routes as ReactRoutes, Route } from 'react-router-dom';
import WithLayout from './WithLayout';

import { Main as MainLayout } from './layouts';

import {
  Home as HomeView,
  Contact as ContactView,
  About as AboutView,
  Numerology as NumerologyView,
  Yoga as YogaView,
  Holistic as HolisticView,
} from './views/mainPages';


const Routing = () => {
  return (
    <ReactRoutes>
      <Route
        exact
        path="/"
        element={((matchProps) => (
          <WithLayout
            {...matchProps}
            component={HomeView}
            layout={MainLayout}
          />
        ))()}
      />
      <Route
        exact
        path="/contacto"
        element={((matchProps) => (
          <WithLayout
            {...matchProps}
            component={ContactView}
            layout={MainLayout}
          />
        ))()}
      />
      <Route
        exact
        path="/ceremonias-holísticas"
        element={((matchProps) => (
          <WithLayout
            {...matchProps}
            component={HolisticView}
            layout={MainLayout}
          />
        ))()}
      />
      <Route
        exact
        path="/sobre"
        element={((matchProps) => (
          <WithLayout
            {...matchProps}
            component={AboutView}
            layout={MainLayout}
          />
        ))()}
      />
      <Route
      exact
      path="/yoga"
      element={((matchProps) => (
        <WithLayout
          {...matchProps}
          component={YogaView}
          layout={MainLayout}
        />
      ))()}
    />
  <Route
  exact
  path="/numerología"
  element={((matchProps) => (
    <WithLayout
      {...matchProps}
      component={NumerologyView}
      layout={MainLayout}
    />
  ))()}
/>
</ReactRoutes>
  );
};

export default Routing;