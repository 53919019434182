export const pages = [
  {
    title: 'Landing Pages',
    id: 'landing-pages',
    pages: [
      {
        title: 'Inicio',
        href: '/',
      },
      {
        title: 'Yoga',
        href: '/yoga',
      },
      {
        title: 'Numerología',
        href: '/numerología',
      },
      {
        title: 'Ceremonias Holísticas',
        href: '/ceremonias-holísticas',
      },
      {
        title: 'Contacto',
        href: '/contacto',
      },
      {
        title: 'Sobre',
        href: '/sobre',
      },
    ],
  },
];
