/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
//import WateringIllustration from '../../../../../svg/illustrations/Watering';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';

const HideOnScroll = ({ children }) => {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger} timeout={{exit: 1000}}>
      {children}
    </Slide>
  );
};

const Hero = ({children}) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  return (
 /*   <Grid container spacing={4} paddingTop={2}>
      <Grid item container alignItems="center" justifyContent={'center'} xs={12} md={12}>
      */
        <Box
        data-aos={isSm ? 'fade-right' : 'fade-up'}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        >
            <Box>
            <Typography
              variant="h2"
              color="white"
              align="center"
              sx={{
                fontWeight: 600,
              }}
            >
              Holistic Experience
            </Typography>
            </Box>
          <Box paddingTop={0} display={'flex'} justifyContent={'center'}>
          <Button
            fullWidth={isSm ? false : true}
            sx={{
              padding: 1.75, 
              margin: 2,borderRadius: 8, 
              color: 'white', 
              borderColor: 'white',
              borderWidth: 2,
            }}
            component={'a'}
            href={'packages'}
            //target={'_blank'}
            variant="outlined"
            
            
          >
            <Typography
              variant="h5"
              align="center"
              sx={{
                fontWeight: 600,
              }}
            >
              Comenzar
              </Typography>
          </Button>
          </Box>
        </Box>
        /*
      </Grid>
    </Grid>
    */
  );
};

export default Hero;
