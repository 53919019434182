import React from 'react'
import LargeYoga from '../../../images/largeyoga.jpg';
import { ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';

function Holistic() {
  return (
    <div>
    <ParallaxBanner style={{ aspectRatio: '2 / 1' }}>
  <ParallaxBannerLayer image={LargeYoga} speed={-20} />
</ParallaxBanner>
<div style={{height: '100vh', width: '100vw', backgroundColor: 'black'}}>
  <h1>holistic</h1>
</div>
<ParallaxBanner style={{ aspectRatio: '2 / 1' }}>
  <ParallaxBannerLayer image={LargeYoga} speed={-20} />
</ParallaxBanner>
</div>
  )
}

export default Holistic