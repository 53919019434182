import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routing from './routing'
import { ParallaxProvider } from 'react-scroll-parallax';


const App = () => {
  return (
    <ParallaxProvider>
        <BrowserRouter>
          <Routing />
        </BrowserRouter>
    </ParallaxProvider>
  );
};
export default App;
